<template>
  <div class="control">
    <NavBar />
    <h1>Управление</h1>
    <v-tabs class="ma-5" slider-size="3" background-color="transparent" color="red">
      <v-tab to="cafes">Рестораны</v-tab>
      <v-tab v-if="checkPermission([128])" to="tags">Теги</v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NavBar from '@/components/NavBar';

export default {
  data() {
    return {
      search: '',
      notEmpty: [(v) => v.length >= 0 || 'Выберите станцию'],
      stationToken: '',
      showCafes: false,
      choosenTag: []
    };
  },
  components: {
    NavBar
  },
  mounted() {
    this.updateCafesList();
  },
  computed: {
    tabs() {
      return [
        { name: 'Рестораны', route: 'cafes' },
        { name: 'Теги', route: 'tags' }
      ];
    },
    ...mapGetters('auth', ['userPerm'])
  },
  methods: {
    updateCafesList() {
      this.getAllCafesList();
    },
    checkPermission(value) {
      return this.userPerm.find((el) => value.includes(el)) !== undefined;
    },
    ...mapActions('admin', ['fetchStationsList']),
    ...mapActions('admin', ['getAllCafesList'])
  }
};
</script>
<style scoped>
.v-image,
video {
  border-radius: 10px;
}
</style>
